import { createStore, applyMiddleware, combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import promise from 'redux-promise-middleware';
import {
  loadingBarReducer,
  loadingBarMiddleware,
} from 'react-redux-loading-bar';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import challengeService from './modules/challenge';
import scoreboardService from './modules/scores';

// the order matters
let middleware = [promise, loadingBarMiddleware()];

// use logger middleware only in dev mode
if (process.env.NODE_ENV === 'development') {
  const loggerMiddleware = createLogger();
  // logger middleware must me last to catch all actions in the chain
  middleware = [...middleware, loggerMiddleware];
}

const createStoreWithMiddleware = applyMiddleware(...middleware)(createStore); // apply logger to redux

const reducer = combineReducers({
  challenges: persistReducer(
    { key: 'ovis:challenges', blacklist: ['fetchedAt'], storage },
    challengeService
  ),
  scoreboard: persistReducer(
    { key: 'ovis:scoreboard', blacklist: ['fetchedAt'], storage },
    scoreboardService
  ),
  loadingBar: loadingBarReducer,
});

const configureStore = (initialState) => {
  const store = createStoreWithMiddleware(reducer, initialState);
  const persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore;
