import { request } from '../utils';

export const challengesFetchAll = () => request(`/challenges`);

export const challengesSolve = (id, flag) =>
  request(`/challenges/${id}/solve`, {
    method: 'post',
    body: JSON.stringify({
      flag,
    }),
  });

export const scoresFetch = () => request(`/scoreboard?include_series_limit=10`);
