import React, { memo } from 'react';

const NoticePage = ({ text }) => {
  return (
    <div className="container mx-auto px-8 py-8 pt-8">
      <div className="w-full h-full">
        <p className="text-pink-500 text-center block font-bold pb-2 px-8 py-8 pt-8">
          {text}
        </p>
      </div>
    </div>
  );
};

export default memo(NoticePage);
