const BASE_URL =
  'https://dkosbkcm9k.execute-api.eu-central-1.amazonaws.com/api/';

export default function request(path, options) {
  let enhancedOptions = {
    ...options,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const url = `${BASE_URL}${path}`;
  return fetch(url, enhancedOptions).then((res) => {
    return res.json().then((data) => {
      if (!res.ok) {
        throw Error(JSON.stringify(data));
      }
      return data;
    });
  });
}
