import React, { memo } from 'react';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';

import Routes from '../../routes';

const NavBarItem = (props) => {
  const { navRoute, currentRoute } = props;

  const { friendlyName, slug } = navRoute;
  const selected = slug === currentRoute;

  const classes = selected
    ? 'text-center block py-1 bg-black text-pink-500 rounded'
    : 'text-center block text-white hover:text-pink-500 py-1';

  return (
    <li className="flex-1 mr-2">
      <Link className={classes} to={slug} role="button">
        {friendlyName}
      </Link>
    </li>
  );
};

const NavBar = (props) => {
  const currentRoute = props.location.pathname;
  return (
    <div className="flex justify-center bg-gray-900">
      <ul className="flex w-full md:w-3/2 lg:w-1/2 py-4 px-8 bg-gray-900 text-sm md:text-base">
        <NavBarItem navRoute={Routes.SCOREBOARD} currentRoute={currentRoute} />
        <NavBarItem navRoute={Routes.CHALLENGES} currentRoute={currentRoute} />
      </ul>
    </div>
  );
};

export default compose(memo, withRouter)(NavBar);
