import React, { memo, useState } from 'react';
import ReactTooltip from 'react-tooltip';

const AchievementBadge = ({ player, name, badge, time_unlocked, challs }) => {
  const id = `${player}-${name}`.replaceAll(/\s+/g, '-').toLowerCase();
  return (
    <>
      <span
        data-tip
        data-for={id}
        className="inline-block mx-1 md:mx-2 cursor-pointer"
      >
        <img
          src={badge}
          className="h-5 w-5 md:h-6 md:w-6 inline-block align-middle"
          alt={name}
        />
      </span>
      <ReactTooltip id={id} effect="solid">
        {name}
      </ReactTooltip>
    </>
  );
};

const RankBadge = ({ rank }) => {
  if (rank >= 1 && rank <= 3) {
    const medals = ['🥇', '🥈', '🥉'];
    const medal = medals[rank - 1];
    return (
      <span role="img" aria-label={`${rank}-medal`}>
        {medal}
      </span>
    );
  }

  return <span>{rank}</span>;
};

const ScoreboardRow = ({
  rank,
  name,
  points = 0,
  achievements,
  solvedChallenges = [],
}) => {
  const [expanded, setExpanded] = useState(false);

  let bg = 'border-gray-900 bg-gray-300';
  if (rank === 1) {
    bg = 'border-yellow-400 bg-yellow-400';
  } else if (rank === 2) {
    bg = 'border-indigo-400 bg-indigo-400';
  } else if (rank === 3) {
    bg = 'border-orange-800 bg-yellow-800';
  }

  return (
    <li
      className={`w-full border-2 rounded ${bg} bg-opacity-25 my-4 px-4 md:px-8 py-4 pink-600 cursor-pointer hover:text-gray-400 text-sm md:text-base`}
      onClick={() => setExpanded(!expanded)}
    >
      <div className="flex justify-between flex-wrap items-center">
        <span className="flex w-auto sm:w-16 lg:w-32 xl:w-64">
          <RankBadge rank={rank} />
        </span>
        <span className="flex flex-1 justify-center sm:justify-between items-center flex-wrap">
          <span className="text-center sm:text-left mx-4 md:mx-8">{name}</span>
          <span className="mt-1 sm:mt-0 w-full sm:w-auto text-center sm:text-right flex justify-center sm:justify-evenly mx-4 md:mx-8">
            {achievements.map((x, idx) => (
              <AchievementBadge key={idx} player={name} {...x} />
            ))}
          </span>
        </span>
        <span className="text-right w-auto sm:w-16 lg:w-32 xl:w-64">
          {points}
        </span>
      </div>
      {expanded && (
        <>
          <div className="text-gray-400 mt-2 font-sm text-center">{`Solved ${solvedChallenges.length} Challenges`}</div>
          <p className="text-gray-400 mt-2 text-center">{`${solvedChallenges
            .map((c) => c.challname)
            .join(', ')}`}</p>
        </>
      )}
    </li>
  );
};

export default memo(ScoreboardRow);
