import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Fuse from 'fuse.js';

import ChallengesRow from '../ChallengesRow';

import * as ChallengeActions from '../../redux/modules/challenge';
import NoticePage from '../NoticePage';
import Reloader from '../Reloader';

const Challenges = (props) => {
  const { challenges = [], fetchedAt, loading } = props;

  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [fuse, setFuse] = useState(null);

  useEffect(() => {
    if (fetchedAt) {
      return;
    }
    props
      .fetchAllChallenges()
      .then(() => setError(''))
      .catch((e) => setError(e.message));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fuseOpts = {
      keys: ['name', 'author', 'category', 'difficulty', 'solves.username'],
      threshold: 0.2,
      useExtendedSearch: true,
    };
    setFuse(new Fuse(challenges, fuseOpts));
  }, [challenges]);

  if ((!challenges || challenges.length === 0) && error.length === 0) {
    return <NoticePage text="Fetching challenges..." />;
  }

  const filtered =
    searchTerm.length > 0
      ? fuse.search(searchTerm).map((x) => x.item)
      : challenges;

  return (
    <div className="container mx-auto px-2 md:px-8 py-4 md:py-8 text-white">
      <Reloader
        loading={loading}
        reloadCallback={props.fetchAllChallenges}
        dateFetched={fetchedAt}
      />
      <div className="flex justify-center">
        <input
          className={`px-2 md:px-8 py-4 bg-gray-800 border-2 rounded border-gray-900 w-full focus:outline-none focus:ring focus:border-${
            filtered.length > 0 ? 'green-300' : 'pink-300'
          } text-sm md:text-base`}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search..."
        />
      </div>
      <ul className="container overflow-auto">
        {filtered.map((x) => (
          <ChallengesRow
            key={x.id}
            onSubmitFlag={null}
            categories={x.category}
            totalSolves={x.solves.length}
            {...x}
          />
        ))}
      </ul>
      {error && error.length > 0 && (
        <p className="text-red-500 text-center text-xs mx-auto">{error}</p>
      )}
      {filtered.length === 0 && (
        <div className="w-full h-full">
          <p className="text-pink-500 text-center block font-bold pb-2 px-8 py-8 pt-8">
            Nothing to see here
          </p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  challenges: store.challenges.challenges,
  fetchedAt: store.challenges.fetchedAt,
  loading: store.challenges.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllChallenges: () => dispatch(ChallengeActions.fetchAll()),
  solveChallenge: (id, flag) => dispatch(ChallengeActions.solve(id, flag)),
});

export default compose(
  memo,
  connect(mapStateToProps, mapDispatchToProps)
)(Challenges);
