import React from 'react';

const Reloader = ({ loading, reloadCallback, dateFetched }) => (
  <div className="text-sm text-gray-500 text-center pb-4 md:pb-8">
    <button
      className={`align-middle mr-3 rounded bg-gray-900 px-6 py-1 ${
        loading ? 'text-white' : 'hover:text-white text-gray-500'
      }`}
      onClick={reloadCallback}
      disabled={loading}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`h-4 w-4 transition ${loading ? 'animate-spin' : ''}`}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
        />
      </svg>
    </button>
    <span className="">{`Updated at `}</span>
    {dateFetched ? (
      <>
        <span className="">{`${new Date(
          dateFetched
        ).toLocaleTimeString()}`}</span>
      </>
    ) : (
      <span>...</span>
    )}
  </div>
);

export default Reloader;
