import React from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';
import LoadingBar from 'react-redux-loading-bar';

import configureStore from './redux/configureStore';
import Routes from './routes';

import Scoreboard from './components/Scoreboard';
import Challenges from './components/Challenges';
import NavBar from './components/NavBar';

function App() {
  const { store } = configureStore();

  return (
    <Provider store={store}>
      <LoadingBar style={{ height: '3px', backgroundColor: '#d53f8c' }} />
      <div className="mx-auto bg-gray-800 h-screen font-mono tracking-wider overflow-auto">
        <Router>
          <NavBar />
          <Switch>
            <Route path={Routes.SCOREBOARD.slug}>
              <Scoreboard />
            </Route>
            <Route path={Routes.CHALLENGES.slug}>
              <Challenges />
            </Route>
            <Route path="/">
              <Redirect to={Routes.SCOREBOARD.slug} />
            </Route>
          </Switch>
        </Router>
      </div>
    </Provider>
  );
}

export default App;
