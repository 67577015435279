import * as API from './api';
import {
  prefixActionName,
  fulfilled,
  pending,
  rejected,
} from '../utils/actionName';

const FETCH_ALL = prefixActionName('challenge', 'FETCH_ALL');
const SOLVE = prefixActionName('challenge', 'SOLVE');

export function fetchAll() {
  return {
    type: FETCH_ALL,
    payload: API.challengesFetchAll(),
  };
}

export function solve(id, flag) {
  return {
    type: SOLVE,
    payload: API.challengesSolve(id, flag),
    meta: { id },
  };
}

const initialState = {
  challenges: [],
  fetchedAt: null,
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fulfilled(FETCH_ALL):
      return {
        ...state,
        challenges: action.payload.challenges,
        fetchedAt: Date.now(),
        loading: false,
      };
    case pending(FETCH_ALL):
      return {
        ...state,
        loading: true,
      };
    case rejected(FETCH_ALL):
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
