import React, { memo, useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import NoticePage from '../NoticePage';
import Reloader from '../Reloader';

import * as ScoresActions from '../../redux/modules/scores';

import ScoreboardRow from '../ScoreboardRow';
import Scoregraph from '../Scoregraph';

const Scoreboard = (props) => {
  const { scores = [], fetchedAt, loading } = props;

  const [error, setError] = useState('');

  useEffect(() => {
    if (fetchedAt) {
      return;
    }
    props
      .fetchScores()
      .then(() => setError(''))
      .catch((e) => setError(e.message));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if ((!scores || scores.length === 0) && error.length === 0) {
    return <NoticePage text="Fetching scoreboard..." />;
  }

  return (
    <div className="container mx-auto px-4 md:px-8 py-4 md:py-8 text-white">
      <Reloader
        loading={loading}
        reloadCallback={props.fetchScores}
        dateFetched={fetchedAt}
      />
      <Scoregraph seriesData={scores.slice(0, 10)} />
      <ul className="container overflow-auto">
        {scores.map((o, rank) => (
          <ScoreboardRow
            key={rank + 1}
            rank={rank + 1}
            name={o.username}
            points={o.current_score}
            achievements={o.achievements}
            solvedChallenges={o.challenges_solved}
          />
        ))}
      </ul>
      {error && error.length > 0 && (
        <p className="text-red-500 text-center text-xs mx-auto">{error}</p>
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  scores: store.scoreboard.scores,
  fetchedAt: store.scoreboard.fetchedAt,
  loading: store.scoreboard.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchScores: () => dispatch(ScoresActions.scoresFetch()),
});

export default compose(
  memo,
  connect(mapStateToProps, mapDispatchToProps)
)(Scoreboard);
