import * as API from './api';
import {
  prefixActionName,
  fulfilled,
  pending,
  rejected,
} from '../utils/actionName';

const FETCH_ALL = prefixActionName('scores', 'FETCH_ALL');

const initialState = {
  scores: [],
  fetchedAt: null,
  loading: false,
};

export const scoresFetch = () => ({
  type: FETCH_ALL,
  payload: API.scoresFetch(),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fulfilled(FETCH_ALL):
      return {
        ...state,
        scores: action.payload.scores,
        fetchedAt: Date.now(),
        loading: false,
      };
    case pending(FETCH_ALL):
      return {
        ...state,
        loading: true,
      };
    case rejected(FETCH_ALL):
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
