import React from 'react';
import ReactECharts from 'echarts-for-react';
import dayjs from 'dayjs';

const Scoregraph = ({ seriesData = [] }) => {
  const series = seriesData.map((x) => {
    return {
      type: 'line',
      name: x.username,
      data: x.points_over_time.map((b) => b.points),
      showSymbol: false,
      symbol: 'circle',
      symbolSize: 4,
      smooth: true,
      lineStyle: {
        width: 2,
      },
      emphasis: {
        focus: 'series',
      },
    };
  });
  const xAxisData = seriesData[0].points_over_time.map((t) => {
    const d = dayjs.unix(t.timestamp);
    return d.format('MMM DD\nHH:mm');
  });
  const legend = seriesData.map((x) => x.username);

  const option = {
    title: {},
    textStyle: {
      fontFamily: 'Monospace',
    },
    color: [
      '#FDE047',
      '#60A5FA',
      '#F97316',
      '#99F6E4',
      '#EC4899',
      '#FB923C',
      '#059669',
      '#F9A8D4',
      '#8B5CF6',
      '#E5E7EB',
      '#67E8F9',
    ],
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      show: true,
      data: legend,
      textStyle: {
        color: '#fff',
        fontWeight: 'normal',
        ellipsis: '...',
        overflow: 'truncate',
      },
    },
    toolbox: {
      show: false,
      feature: {
        dataView: { readOnly: true },
        saveAsImage: {},
      },
    },
    dataZoom: {
      show: false,
      start: 0,
      end: 100,
    },
    xAxis: {
      boundaryGap: true,
      data: xAxisData,
    },
    yAxis: {
      type: 'value',
      scale: true,
      min: 0,
      boundaryGap: [0.2, 0.2],
      splitLine: {
        lineStyle: {
          color: '#666',
        },
      },
    },
    series: series,
    media: [
      {
        query: {
          maxWidth: 640,
        },
        option: {
          yAxis: {
            show: false,
            max: 'dataMax',
          },
          xAxis: {
            axisLabel: {
              showMinLabel: false,
              showMaxLabel: false,
            },
          },
          legend: {
            show: false,
          },
          grid: {
            left: 8,
            right: 8,
            top: 0,
            bottom: 40,
          },
        },
      },
      {
        option: {
          xAxis: {
            axisLabel: {
              showMinLabel: true,
            },
          },
          yAxis: {
            show: true,
            scale: true,
            max: null,
          },
          legend: {
            show: true,
          },
          grid: {
            left: 60,
            right: 60,
            bottom: 60,
            top: 60,
          },
        },
      },
    ],
  };

  return (
    <ReactECharts option={option} style={{ height: '50vw', maxHeight: 400 }} />
  );
};

export default Scoregraph;
