import React, { memo, useState } from 'react';

const CategoryBadge = ({ category }) => {
  let color = '';

  const c = category.toLowerCase();

  switch (c) {
    case 'web':
      color = 'blue';
      break;
    case 'crypto':
      color = 'purple';
      break;
    case 'pwn':
      color = 'orange';
      break;
    case 'reverse':
      color = 'yellow';
      break;
    case 'forensics':
      color = 'indigo';
      break;
    case 'misc':
      color = 'gray';
      break;
    case 'mobile':
      color = 'red';
      break;
    default:
      color = 'gray';
  }

  return (
    <span
      className={`bg-${color}-300 border-${color}-900 text-${color}-800 px-2 py-1 border rounded`}
    >
      {c}
    </span>
  );
};

const ChallengesRow = ({
  _id,
  author = '',
  categories,
  name,
  description = '',
  points = 0,
  url,
  solved = false,
  totalSolves = 0,
  flagFormat = 'flag{327a6c4304ad5938eaf0efb6cc3e53dc}',
  solves = [],
  difficulty,
  onSubmitFlag = () => {},
}) => {
  const [expanded, setExpanded] = useState(false);
  const [flag, setFlag] = useState('');
  const [msg, setMsg] = useState('');

  const bg = solved
    ? 'border-green-900 bg-green-300'
    : 'border-pink-900 bg-gray-800';

  const hover = solved
    ? 'text-green-900 hover:text-green-700'
    : 'hover:text-pink-500';

  const descriptionColor = solved ? 'text-green-900' : 'text-gray-400';

  const doSubmitFlag = () => {
    if (!flag || !flag.length) {
      setMsg("Where's the flag?");
      return;
    }
    setMsg('');
    onSubmitFlag(_id, flag)
      .catch(console.error)
      .then((res) => {
        const { solved } = res.value;
        if (solved) {
          setMsg('Boom! Well Done! 🎉');
        } else {
          setMsg('Nope. Try harder.');
        }
      });
  };

  return (
    <li
      className={`w-full border-2 rounded ${bg} bg-gray-900 my-4 px-4 md:px-8 py-4 cursor-pointer ${hover} text-sm md:text-base`}
      onClick={() => {
        setExpanded(!expanded);
        setMsg('');
      }}
    >
      <div className="flex flex-wrap justify-between flex-col sm:flex-row">
        <span className="flex-1 text-left mr-4 whitespace-no-wrap">{`${name}`}</span>
        <span className="flex-1 flex justify-between">
          <span className="flex-1 text-left">{`${points}pts`}</span>
          <span className="flex-1 text-center text-gray-600">
            {`[${difficulty}]`}
          </span>
          <span className="flex-1 text-right">
            <CategoryBadge category={categories[0] || 'N/A'} />
          </span>
        </span>
      </div>
      {expanded && (
        <>
          <div className={`${descriptionColor} font-sm flex mt-2 text-right`}>
            <span className="flex-1 text-gray-600 text-left mr-4">{`${author}`}</span>
            <span className="flex-1 text-gray-600 text-right sm:text-left">{`${
              solves.length
            } ${solves.length === 1 ? 'solve' : 'solves'}`}</span>
          </div>
          <p className={`${descriptionColor} mt-2 font-sm`}>{`${solves
            .map((x) => x.username)
            .join(', ')}`}</p>
          {!solved && onSubmitFlag && (
            <div className="flex mt-8">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 font-sm leading-tight focus:outline-none focus:shadow-outline border-green-300 rounded-r-none"
                placeholder={flagFormat}
                onChange={(e) => setFlag(e.target.value)}
                type="text"
              ></input>
              <button
                className="bg-green-700 hover:text-green-200 text-green-100 border-green-900 py-2 px-16 rounded rounded-l-none"
                onClick={doSubmitFlag}
              >
                Submit
              </button>
            </div>
          )}
          {msg && msg.length && (
            <p className="text-white mt-2 font-sm">{msg}</p>
          )}
        </>
      )}
    </li>
  );
};

export default memo(ChallengesRow);
